<template>
  <div class="grid grid4">
    <value-card 
      background-class="bg-info"
      label="Aantal gebruikers laatste week"
      :value="statistics.users?.usersAccessedInLastWeek ?? 0"
      font-awesome-icon-name="calendar"
    />

    <value-card 
      background-class="bg-secondary"
      label="Aantal gebruikers laatste maand"
      :value="statistics.users?.usersAccessedInLastThirtyDays ?? 0"
      font-awesome-icon-name="calendar"
    />

    <value-card 
      background-class="bg-success"
      label="Actieve gebruikers"
      :value="statistics.users?.activeCount ?? 0"
      font-awesome-icon-name="user-plus"
    />

    <value-card 
      background-class="bg-dark"
      label="Nieuw gebruikers laatste maand"
      :value="statistics.users?.newUsersInLast30days ?? 0"
      font-awesome-icon-name="user-plus"
    />

    <value-card 
      background-class="bg-success"
      label="Totaal aantal gebruikers"
      :value="statistics.users?.count ?? 0"
      font-awesome-icon-name="users"
    />

    <value-card 
      background-class="bg-gray-800"
      label="Aantal nieuwsberichten"
      :value="statistics.news?.count ?? 0"
      font-awesome-icon-name="newspaper"
    />

    <value-card 
      background-class="bg-warning"
      label="Aantal evenementen"
      :value="statistics.events?.count ?? 0"
      font-awesome-icon-name="clock"
    />

    <value-card 
      background-class="bg-gray-400"
      label="Aankomende evenementen"
      :value="statistics.events?.futureEventCount ?? 0"
      font-awesome-icon-name="clock"
    />

    <value-card
      background-class="bg-gray-600"
      label="Aantal bedrijven"
      :value="statistics.companies?.count ?? 0"
      font-awesome-icon-name="building"
    />

    <value-card
      background-class="bg-info"
      label="Aantal chats"
      :value="statistics.chats?.count ?? 0"
      font-awesome-icon-name="comment"
    />

    <multi-value-card
      background-class="bg-gray-400"
      :labels="[ 'Vraag', 'Aanbod' ]"
      :values="[ statistics.pinboard?.requests ?? 0, statistics.pinboard?.offers ?? 0]"
      font-awesome-icon-name="thumbtack"
    />

    <multi-value-card
      v-if="pollModuleIsActive"
      background-class="bg-success"
      :labels="[ 'Aantal polls', 'gemiddeld stempercentage' ]"
      :values="[ statistics.polls?.count ?? 0, statistics.polls?.votePercentage ?? 0.00 ]"
      font-awesome-icon-name="poll-h"
      decimal-value
      show-percentage-symbol
    />
  </div>
</template>

<script>
import MultiValueCard from '@/components/Dashboard/MultiValueCard.vue';
import ValueCard from '@/components/Dashboard/ValueCard.vue';
import { mapGetters } from 'vuex';

import StatisticsService from '@/domain/services/statistics';

import { moduleIsActive } from '@/helpers/module';

export default {
  components: {
    MultiValueCard,
    ValueCard
  },

  data() {
    return {
      statisticsData: null,
      pollModuleIsActive: false
    };
  },

  computed: {
    ...mapGetters('account', [
      'currentOrganization'
    ]),
    statistics() {
      return this.statisticsData ?? {}
    }
  },

  async mounted() {
    this.pollModuleIsActive = await moduleIsActive("Poll");
  },

  async updated() {
    if (this.currentOrganization?.code == null) return;

    this.statisticsData = await StatisticsService.getStatisticsAsync();
  }
};
</script>

<style scoped>
.col-4 {
  flex-direction: column;
  display: flex;
  gap: 20px;
}
</style>